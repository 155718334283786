import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import $ from 'jquery'

import {UNIVERSITY_API_URL, getHeader, DATE_FORMAT, getUnivID} from '../../Config';
import Progress from "react-progress-2";
import {Tabs, Tab, Panel} from 'react-bootstrap';
import moment from 'moment'
import { browserHistory } from 'react-router';

class ForgotPassword extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
			email:'',
			role:'3',
			loading:false
        };

    }
    componentDidMount() {		
		
    }
    componentWillUnmount() {
		if(this.serverRequest){
			this.serverRequest.abort();
		}
    }
    handleChange = (key) => {
		return function (e) {
			var state = {};
			state[key] = e.target.value;
			this.setState(state);
		}.bind(this);
	};
	onSend = (e) =>{
		e.preventDefault();
		if(this.serverRequest){
			return;
		}
		if(this.state.email==''){
			$('#statusBox').html('<div class="alert alert-danger" role="alert">Masukkan email</div>');
			ReactDOM.findDOMNode(this.refs.statusBox).focus();
			window.scrollTo(0, 0);
			return false;
		}
		
		var datax = {			
			UniversityID:getUnivID(),
			email: this.state.email,			
		};
		var that = this;
		var urlx = '';
		switch(this.state.role){
			case '1':
				urlx = UNIVERSITY_API_URL+"/auth/forgot";
				break;
			case '2':
				urlx = UNIVERSITY_API_URL+"/auth/staff-forgot";
				break;
			case '3':
				urlx = UNIVERSITY_API_URL+"/auth/student-forgot";
				break;
		}
		this.serverRequest = $.ajax({
			type: 'POST',
			url: urlx,
			headers: getHeader(),
			data: JSON.stringify(datax),
			success:function(data){
				if(data.status==1){
					console.log(data);
					
					window.notify('Silahkan check email anda: '+that.state.email, 'success');
				}else{
					window.notify(data.message, 'error');
				}
				that.serverRequest =null;
			},
			error: function(data) { // if error occured
				window.handleAjaxError(data);				
				that.serverRequest =null;
			}
		})
		.done(function(data) {
			that.serverRequest =null;
		})
	}
    render() {
        return (
        <div>           
			<Progress.Component/>
			
			<div className="page-header row">
				<div className="col-xs-10">
					<h1>Forgot Password 	</h1>
				</div>
				<div className="col-xs-2">
					<span className="text-right"><a href="/" >Login</a></span>
				</div>
				
				
			</div>
            <div className="page-content">
			{!this.state.loading ? 
				<div className="col-md-6 col-md-offset-3">
					<form onSubmit={this.onSend} className="form-horizontal" >
						<div id="statusBox" ref="statusBox">					
						</div>
						<p>Masukkan email anda dan kami akan mengirim instruksi untuk mereset password ke email tersebut.</p>
						<div className="form-group">
							<label htmlFor="txtEmail" className="col-sm-4 control-label">Email</label>
							<div className="col-sm-8">
								<input
									id="txtEmail"
									type='text'
									className='form-control'
									name="email"
									value={this.state.email}
									onChange={this.handleChange('email')}
									 />
							</div>
						</div>
						<div className="form-group">
							<label htmlFor="txtEmail" className="col-sm-4 control-label">Role</label>
							<div className="col-sm-8">
								<select id="ddlRole" value={this.state.role} className="form-control" onChange={this.handleChange('role')}>									
									<option value="3">Mahasiswa</option>			
									<option value="2">Staff</option>			
									<option value="1">Admin</option>												
								</select>
							</div>
						</div>
								
						<div className="form-group">
							<div className="col-sm-12">
								<button
								className='btn btn-primary pull-right'
								onClick={this.onSend}>Kirim</button>
							</div>	
						</div>
						<input type="hidden" name="UniversityID" value="" ref="univId" /> 
					</form>
				</div>	
            : <p className="progressIndicator">Loading...</p>}
            </div>
        </div>
        );
    }
}

export default ForgotPassword;
