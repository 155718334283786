import $ from 'jquery'
import {UNIVERSITY_API_URL, getHeader} from './Config';

let lastTime = 0;

export default {
  login(username, pass, univId, cb) {
	  clearLocalStorage();
    cb = arguments[arguments.length - 1]
    if (localStorage.token) {
      if (cb) cb(true)
      this.onChange(true)
      return
    }
    pretendRequest(username, pass, univId, (res) => {
      if (res.authenticated) {       
        if (cb) cb(true)
        this.onChange(true)
      } else {
        if (cb) cb(false)
        this.onChange(false)
      }
    })
  },

  getToken() {
    return localStorage.token
  },

  logout(cb) {
    clearLocalStorage();
    if (cb) cb()
    this.onChange(false)
  },

  loggedIn() {
	  //get token
	  const ret = !!localStorage.token;
	  if ( Math.floor((new Date() - lastTime)/60000) < 2 ) {
	  }else {
    	//more than 2 minutes
	    lastTime =  new Date();
	     //check whether expired
	     $.ajax({		
			type: 'GET',
			crossDomain: true,
			url: `${UNIVERSITY_API_URL}/auth/verify`,				
			headers: getHeader(),
			success(data) {			
				console.log(data);
				if(data.status==1){
					
				}else{
					alert('You are not authenticated. Please re-login');
					clearLocalStorage();
					window.location.href = "/student/login";
				}
			},
			error(data) { // if error occured
				if(data.responseText){
					try{
						const responseText = $.parseJSON(data.responseText);			
						console.log(responseText);
						if(responseText.error){
							alert(responseText.error.message);
							clearLocalStorage();
							window.location.href = "/student/login";
						}
					}catch(e){
						console.log(data);
					}
				}else{
					console.log(data);
				}
				
			}
		})
		.done(data => {
			
		})
	  }
	  
    return ret;
  },

  onChange() {}
};

function pretendRequest(username, pass, univId, cb) {	
	// Submit form via jQuery/AJAX
	const datax = {username, password:pass, universityID: univId};
	
	$.ajax({		
		type: 'POST',
		crossDomain: true,
		url: `${UNIVERSITY_API_URL}/auth/staff-student-login`,		
		data: JSON.stringify(datax),
		headers: {
		   "X-Requested-With": "XMLHttpRequest",
		   "Content-Type": "application/json"
		},
		success(data) {
			console.log(data);
			if(data.status==1){
				
				if(data.type==='STUDENT'){	
					localStorage.UserType =	'STUDENT';			
					localStorage.token = data.message;
					localStorage.university = JSON.stringify(data.university);
					localStorage.ProgramName = data.ProgramName;
					localStorage.ProgramID = data.ProgramID;
					localStorage.StudentEnrollmentID = data.StudentEnrollmentID;					
					localStorage.UserID = data.UserID;
					localStorage.avatarURL = data.avatarURL;
					localStorage.NIM = data.NIM;
					localStorage.name = data.name;
				}else if(data.type=='STAFF'){
					localStorage.UserType =	'STAFF';
					localStorage.token = data.message;
					localStorage.university = JSON.stringify(data.university);        
					localStorage.ActiveSemesterID = data.ActiveSemesterID;
					localStorage.ActiveSemester = JSON.stringify(data.ActiveSemester);
				}
				cb({
					authenticated: true,
					token: data.message,						
				})
				//if (cb) cb(true)
					//this.onChange(true)
			}else{
				
				cb({ authenticated: false })
			}
		},
		error(data) { // if error occured
			if(data.responseText){
				if(data.responseText=='Bad Authorization Header: Bearer' || data.responseText=='Invalid Token'){
					clearLocalStorage();
					console.log(window.location.href);
					
					window.location.href = "/";
					
				}
				try{
					const responseText = $.parseJSON(data.responseText);			
					console.log(responseText);
					if(responseText.error){
						alert(responseText.error.message);
						clearLocalStorage();
						window.location.href = "/";
					}
				}catch(e){
					console.log(data);
				}
			}else{
				console.log(data);
			}
			
		}
	})
	.done(data => {
		
	})
	
}
function clearLocalStorage(){
	localStorage.clear();
}
