import React from 'react';
import ReactDOM from 'react-dom';
//import StackTrace from 'stacktrace-js'
import $ from 'jquery'
import './bootstrap-datepicker'
import App from './App';
//bootstrap
import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/css/bootstrap-theme.css';
//import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import './react-bootstrap-table-all.min.css';
import 'font-awesome/css/font-awesome.css';

// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select/dist/react-select.css';

import "react-progress-2/main.css"

import './bootstrap-datepicker3.min.css';

import './index.css';
/*import Offline from 'offline-js';
Offline.options = {
	// Should we check the connection status immediatly on page load.
	checkOnLoad: true,

	// Should we monitor AJAX requests to help decide if we have a connection.
	interceptRequests: true,

};
//console.log(Offline.check());
Offline.on('up', function() {
    console.log('inet up');
});
Offline.on('down', function() {
    console.log('inet down');
});*/
/*
var callback = function(stackframes) {
  var stringifiedStack = stackframes.map(function(sf) {
    return sf.toString();
  }).join('\n');
  console.log(stringifiedStack);
  var url = window.location.href;
  //alert(stringifiedStack);
  //window.location.reload(); 
};

var errback = function(err) { 
	console.log(err.message); 
	//alert(err.message);
	//window.location.reload(); 
};
window.onerror = function(msg, file, line, col, error) {
    // callback is called with an Array[StackFrame]
    StackTrace.fromError(error).then(callback).catch(errback);
};
*/

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
