import React from 'react';
import $ from 'jquery';

import {UNIVERSITY_API_URL,getHeader} from '../Config';

class Loader extends React.Component {

	constructor(props) {
		super(props);	
		this.state = {
           
        }
        
	}
	
    componentDidMount() {
        
    }
    
    
    render() {
		return (
			<div id="floatingCircleCont">
				<div id="floatingCirclesG">
					<div className="f_circleG" id="frotateG_01"></div>
					<div className="f_circleG" id="frotateG_02"></div>
					<div className="f_circleG" id="frotateG_03"></div>
					<div className="f_circleG" id="frotateG_04"></div>
					<div className="f_circleG" id="frotateG_05"></div>
					<div className="f_circleG" id="frotateG_06"></div>
					<div className="f_circleG" id="frotateG_07"></div>
					<div className="f_circleG" id="frotateG_08"></div>
				</div>	
			</div>); // render null when app is not ready
    }
}

export default Loader;
